import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, {useEffect, useState} from "react";
import { BASE_URL } from "../../assets/js/_variables";

// Componentes
import Layout from "../../components/Layout";
import Slider from "../../components/Slider";

const Canopy = () => {
  const [lang, setLang] = useState()
  
  const txt = {
    en: {
      pageTitle: 'Canopy | HB Privileged',
      title: 'CANOPY RIVER ZIP LINE',
      subtitle: 'TOUR OVERVIEW',
      text: "The best zip line adventure in PV! Fly at 200 meters above a river among the treetops, while you enjoy the majestic scenery at Canopy River. Transportation to the Vallarta jungle is included. You will fly through 11 lines that go zigzagging down to the river where you can land with a splash. Then to get back to were you started you can do it in the back of a flirting mule! Recommended for ages 6 to 9. Weight limit 250 lbs. A must do in Vallarta!",
      frequency: 'Frequency:',
      hours: 'Hours:',
      duration: 'Duration:',
      btn: 'Book now',
    },
    es: {
      pageTitle: 'Canopy | HB Privileged',
      title: 'TIROLESA DEL RÍO CANOPY',
      subtitle: 'RESUMEN DEL TOUR',
      text: 'La mejor aventura de tirolesa en PV! Vuela a 200 metros sobre un río en medio de los árboles, mientras disfrutas del majestuoso paisaje de Canopy River. La transportación a la jungla de Vallarta está incluída. Volarás 11 líneas zigzageando hacia el río donde podrás acuatizar si lo deseas. Después, para regresar al punto donde comenzaste, lo podrás hacer sobre una coqueta mula! Recomendado para edades de 6 a 99 años. Límite de peso 125 kg. Algo que debe de hacer en Vallarta!',
      frequency: 'Frecuencia:',
      hours: 'Horas:',
      duration: 'Duración:',
      btn: 'Reserva este tour',
    }
  }

  const images = [
    `${BASE_URL}/tours/canopi1.jpg`,
    `${BASE_URL}/tours/canopi2.jpg`,
  ]

  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }

  useEffect(() => {
    if(getLang() === 'es') {
      setLang('es')
    } else {
      setLang('en')
    }
  }, [])

  return (
    <Layout pageTitle={ lang === 'es' ? txt.es.pageTitle : txt.en.pageTitle }>
      <div>
        {/*</div><div className="h-80 overflow-hidden">*/}
        <div className="h-70 overflow-hidden">
          <img src={`${BASE_URL}/banners/canopy.jpg`} className="w-full object-cover" alt="" />
        </div>
        <div className="section">
          <h2 className="text-2xl md:text-4xl font-bold mb-2">
            { lang === 'es' ? txt.es.title : txt.en.title }
          </h2>
          <div className="mb-4">
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-xl md:text-2xl font-semibold mb-4">
                { lang === 'es' ? txt.es.subtitle : txt.en.subtitle }
              </h3>
              <p className="normal-text text-justify mb-4">
                { lang === 'es' ? txt.es.text : txt.en.text }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.frequency : txt.en.frequency }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.hours : txt.en.hours }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.duration : txt.en.duration }
              </p>
            </div>

            <div className="">
              <div className="rounded overflow-hidden">
                <Slider images={images} />
              </div>
              
              <div className="text-center py-8">
                <Link
                  className="text-center w-1/2 mt-16 text-xl font-bold bg-sky-800 text-neutral-50 py-2 px-4 rounded-full" 
                  to={`${BASE_URL}/contacto`}
                >
                  { lang === 'es' ? txt.es.btn : txt.en.btn }
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
}
 
export default Canopy;